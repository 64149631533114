import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { Link } from 'gatsby';

const IndexPage = () => (
  <Layout>
    <SEO title="Hello!" />

    {/* INTRODUCTION */}

    <h1 className="mt-10">
      Hi! I'm Yong, a computer engineering graduate from the University of
      Waterloo with experience in both software engineering and product
      management.
    </h1>

    {/* TECH */}
    <h2 className="mt-10 mb-4 uppercase text-gray-500 font-bold">Tech</h2>
    <p>
      During my university years, I had the amazing opportunity to intern at
      companies such as Microsoft, Facebook, and Venmo.
    </p>

    <p className="mt-4">
      I've worked mostly with build systems and Android applications during my
      internships. And, through personal projects, I've built a skillset in web
      development by creating entire web applications.
    </p>

    <p className="mt-4">
      My past internships as both a product manager and software engineer helped
      me form a holistic understanding of product development and what it means
      to be a contributing member of a team.
    </p>

    <p className="mt-4">
      I'm currently contributing across the full-stack as a software engineer at{' '}
      <a
        className="underline hover:text-teal-400"
        target="_blank"
        rel="noopener noreferrer"
        href="https://goformative.com"
      >
        Formative
      </a>
      , an educational tech company providing a digital classroom experience and
      real-time assessments for schools, teachers, and students.
    </p>

    {/* MUSIC */}
    <h2 className="mb-4 mt-10 uppercase text-gray-500 font-bold">Music</h2>
    <p>
      When I'm not cranking out code, I enjoy expressing my creativity through music
      production.
    </p>
    <p className="mt-4">
      I currently collaborate with and help produce songs for{' '}
      <a
        className="underline hover:text-teal-400"
        target="_blank"
        rel="noopener noreferrer"
        href="https://open.spotify.com/artist/73jzrmKSMSbvpZefCgDzVv?si=NEYjftQPQa-dS-kl8V75_Q"
      >
        Marquise Thomas
      </a>
      , an artist based in Seattle, WA.
    </p>
    <p className="mt-4">
      I also help produce, mix, and master songs for{' '}
      <a
        className="underline hover:text-teal-400"
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.youtube.com/channel/UCoGRABtFGU7ng79NqF0KuIQ"
      >
        $stdout
      </a>
      , a rapper that makes funny songs about computer programming.
    </p>

    <div className="bg-gray-400 h-px w-full my-10" />

    <p className="mb-4">
      Thanks for checking out my website! Feel free to contact me{' '}
      <Link to="/contact" className="underline hover:text-teal-400">
        here
      </Link>
      .
    </p>
  </Layout>
);

export default IndexPage;
